import { combineReducers } from 'redux';

import CattrsDuck                  from 'app/ducks/cattrs';
import CreditCardsDuck             from 'app/ducks/credit-cards';
import FavoritesDuck               from 'app/ducks/favorites';
import FxRatesDuck                 from 'app/ducks/fx-rates';
import HappeningsDuck              from 'app/ducks/happenings';
import ModalDriveTrackDuck         from 'app/ducks/modal-drive-track';
import ModalConfirmDonationDuck    from 'app/ducks/modal-confirm-donation';
import NteeCodesDuck               from 'app/ducks/ntee-codes';
import OfflineDonationsDuck        from 'app/ducks/offline-donations';
import Page2faDuck                 from 'app/ducks/page-2fa';
import PageEveryCentDuck           from 'app/ducks/page-every-cent';
import PageHappeningsDuck          from 'app/ducks/page-happenings';
import PageIntlOrgsSearchDuck      from 'app/ducks/page-intl-orgs-search';
import PageOnboardingDuck          from 'app/ducks/page-onboarding';
import PromptsDuck                 from 'app/ducks/prompts';
import { reviewPromptReducer }     from 'app/ducks/review-prompt';
import account                     from 'app/reducers/account';
import app                         from 'app/reducers/app';
import auth                        from 'app/reducers/auth';
import cards                       from 'app/reducers/cards';
import claim                       from 'app/reducers/claim';
import collections                 from 'app/reducers/collections';
import companyAdmin                from 'app/reducers/company-admin/';
import countries                   from 'app/reducers/countries';
import donations                   from 'app/reducers/donations';
import entities                    from 'app/reducers/entities/';
import gifts                       from 'app/reducers/gifts';
import groups                      from 'app/reducers/groups';
import madness                     from 'app/reducers/madness';
import pageBracket                 from 'app/reducers/page-bracket';
import pageBracketGame             from 'app/reducers/page-bracket-game';
import pageCampaign                from 'app/reducers/page-campaign';
import pageCollection              from 'app/reducers/page-collection';
import pageCompanyDashboard        from 'app/reducers/page-company-dashboard';
import pageEmailVerification       from 'app/reducers/page-email-verification';
import pageEmployeeInvite          from 'app/reducers/page-employee-invite';
import pageGiftsSend               from 'app/reducers/page-gifts-send';
import pageGroup                   from 'app/reducers/page-group';
import pageGroups                  from 'app/reducers/page-groups';
import pageHome                    from 'app/reducers/page-home';
import pageNonprofitDashboard      from 'app/reducers/page-nonprofit-dashboard';
import pageNonprofitProfile        from 'app/reducers/page-nonprofit-profile';
import pageNonprofitProfileEdit    from 'app/reducers/page-nonprofit-profile-edit';
import pageNonprofitProfilePreview from 'app/reducers/page-nonprofit-profile-preview';
import pageNonprofits              from 'app/reducers/page-nonprofits';
import pageStatic                  from 'app/reducers/page-static';
import pageUserProfile             from 'app/reducers/page-user-profile';
import pageVolEvent                from 'app/reducers/page-vol-event';
import pageVolunteer               from 'app/reducers/page-volunteer';
import pageVolunteerMe             from 'app/reducers/page-volunteer-me';
import pageWallet                  from 'app/reducers/page-wallet';
import recurringDonations          from 'app/reducers/recurring-donations';
import routing                     from 'app/reducers/routing';
import sessionStorage              from 'app/reducers/session-storage';
import slackConnectNonces          from 'app/reducers/slack-connect-nonces';
import toast                       from 'app/reducers/toast';
import volEvents                   from 'app/reducers/vol-events';
import wallet                      from 'app/reducers/wallet';

const rootReducer = combineReducers({
  reviewPrompt: reviewPromptReducer,
  account,
  app,
  auth,
  cards,
  cattrs: CattrsDuck.reducer,
  claim,
  collections,
  companyAdmin,
  countries,
  creditCards: CreditCardsDuck.reducer,
  favorites: FavoritesDuck.reducer,
  fxRates: FxRatesDuck.reducer,
  donations,
  gifts,
  groups,
  happenings: HappeningsDuck.reducer,
  madness,
  entities,
  modalConfirmDonation: ModalConfirmDonationDuck.reducer,
  modalDriveTrack: ModalDriveTrackDuck.reducer,
  nteeCodes: NteeCodesDuck.reducer,
  offlineDonations: OfflineDonationsDuck.reducer,
  pageBracket,
  pageBracketGame,
  pageCampaign,
  pageCollection,
  pageCompanyDashboard,
  pageEmailVerification,
  pageEmployeeInvite,
  pageGiftsSend,
  pageGroup,
  pageGroups,
  page2fa: Page2faDuck.reducer,
  pageEveryCent: PageEveryCentDuck.reducer,
  pageHappenings: PageHappeningsDuck.reducer,
  pageIntlOrgsSearch: PageIntlOrgsSearchDuck.reducer,
  pageHome,
  pageNonprofitDashboard,
  pageNonprofitProfile,
  pageNonprofitProfileEdit,
  pageNonprofitProfilePreview,
  pageNonprofits,
  pageOnboarding: PageOnboardingDuck.reducer,
  pageStatic,
  pageUserProfile,
  pageVolEvent,
  pageVolunteer,
  pageVolunteerMe,
  pageWallet,
  prompts: PromptsDuck.reducer,
  recurringDonations,
  routing,
  sessionStorage,
  slackConnectNonces,
  toast,
  volEvents,
  wallet,
});

export default rootReducer;
