import React from 'react';
import { connect } from 'react-redux';

import Page2fa                         from 'app/components/auth/page-2fa';
import PageApprovalRequired            from 'app/components/auth/page-approval-required';
import PageEmailVerification           from 'app/components/auth/page-email-verification';
import PageForgotPassword              from 'app/components/auth/page-forgot-pw';
import PageLogin                       from 'app/components/auth/page-login';
import PageSsoLogin                    from 'app/components/auth/page-login-sso';
import PageOnboarding                  from 'app/components/auth/page-onboarding';
import PageSignup                      from 'app/components/auth/page-signup';
import PageSignupEmp                   from 'app/components/auth/page-signup-emp';
import PageSignupInd                   from 'app/components/auth/page-signup-ind';
import PageResendEmpInvite             from 'app/components/auth/page-resend-emp-invite';
import PageVerifyEmail                 from 'app/components/auth/page-verify-email';
import PageBackstageBalance            from 'app/components/backstage/page-balance';
import PageBackstageBalances           from 'app/components/backstage/page-balances';
import PageBackstageBalancesTransfer   from 'app/components/backstage/page-balances-transfer';
import PageBackstageCards              from 'app/components/backstage/page-cards';
import PageBackstageCollections        from 'app/components/backstage/page-collections';
import PageBackstageCompanies          from 'app/components/backstage/page-companies';
import PageBackstageCompany            from 'app/components/backstage/page-company';
import PageBackstageConfig             from 'app/components/backstage/page-config';
import BackstageDashboardOverviewPage  from 'app/components/backstage/page-dashboard-overview';
import PageBackstageFailedCharges      from 'app/components/backstage/page-failed-charges';
import PageBackstageFailedDisb         from 'app/components/backstage/page-failed-disbursements';
import PageBackstageFeatureFlags       from 'app/components/backstage/page-feature-flags';
import PageBackstageIndex              from 'app/components/backstage/page-index';
import PageBackstageNonprofit          from 'app/components/backstage/page-nonprofit';
import PageBackstageNonprofitRequests  from 'app/components/backstage/page-nonprofit-requests';
import PageBackstageNonprofits         from 'app/components/backstage/page-nonprofits';
import PageBackstageNteeCodes          from 'app/components/backstage/page-ntee-codes';
import PageBackstagePendingClaims      from 'app/components/backstage/page-pending-claims';
import PageBackstagePricing            from 'app/components/backstage/page-pricing';
import PageBackstageUser               from 'app/components/backstage/page-user';
import PageBackstageUsers              from 'app/components/backstage/page-users';
import PageCampaign                    from 'app/components/campaigns/page-view';
import PageClaim                       from 'app/components/claim/page';
import PageCollection                  from 'app/components/collection/page';
import PageCadminCampaigns             from 'app/components/company-admin/campaigns/page-campaigns';
import PageCadminEditCampaign          from 'app/components/company-admin/campaigns/page-campaigns-edit';
import PageCadminNewCampaign           from 'app/components/company-admin/campaigns/page-campaigns-new';
import PageCadminCampaignsTemplates    from 'app/components/company-admin/campaigns/page-campaigns-templates';
import PageCadminDashboardEdit         from 'app/components/company-admin/dashboards/page-edit';
import PageCadminDashboardView         from 'app/components/company-admin/dashboards/page-view';
import PageCadminDriveDonations        from 'app/components/company-admin/drives/page-drive-donations';
import PageCadminEmployees             from 'app/components/company-admin/employees/page';
import PageCadminEmployee              from 'app/components/company-admin/employees/page-employee';
import PageCadminEmployeesUpload       from 'app/components/company-admin/employees/page-upload';
import PageCadminCards                 from 'app/components/company-admin/gifts/page-cards';
import PageCadminGifts                 from 'app/components/company-admin/gifts/page-gifts';
import PageCadminGiftsNew              from 'app/components/company-admin/gifts/page-new';
import PageCadminGrants                from 'app/components/company-admin/grants/page-grants';
import PageCadminGrantsCreate          from 'app/components/company-admin/grants/page-grants-create';
import PageCadminGroupEmployees        from 'app/components/company-admin/groups/page-group-employees';
import PageCadminGroups                from 'app/components/company-admin/groups/page-groups';
import PageCadminGroupsEdit            from 'app/components/company-admin/groups/page-groups-edit';
import PageCadminGroupsNew             from 'app/components/company-admin/groups/page-groups-new';
import PageCadminGroupsSettings        from 'app/components/company-admin/groups/page-settings';
import PageCadminJunkDrawer            from 'app/components/company-admin/junk-drawer/page';
import PageCadminBracketsDashboard     from 'app/components/company-admin/madness/page-brackets-dashboard';
import PageCadminBracketsEdit          from 'app/components/company-admin/madness/page-brackets-edit';
import PageCadminBracketsNew           from 'app/components/company-admin/madness/page-brackets-new';
import PageCadminBrackets              from 'app/components/company-admin/madness/page-brackets';
import PageCadminMatchSettings         from 'app/components/company-admin/match-settings/page-general';
import PageCadminNonprofits            from 'app/components/company-admin/match-settings/page-nonprofits';
import PageCadminNteeCodes             from 'app/components/company-admin/match-settings/page-ntee-codes';
import PageCadminMatchInquiries        from 'app/components/company-admin/matches/page-inquiries';
import PageCadminMatches               from 'app/components/company-admin/matches/page-matches';
import PageCadminOfflineDonations      from 'app/components/company-admin/matches/page-offline-donations';
import PageCadminMsTeamsSettings       from 'app/components/company-admin/ms-teams/page-settings';
import PageCadminSubscription          from 'app/components/company-admin/saas/page-subscription';
import PageCadminCattrs                from 'app/components/company-admin/settings/page-cattrs';
import PageCadminSso                   from 'app/components/company-admin/settings/page-sso';
import PageCadminSlackSettings         from 'app/components/company-admin/slack/page-settings';
import PageCadminStats                 from 'app/components/company-admin/stats/page';
import PageCadminStats2                from 'app/components/company-admin/stats/page2';
import PageCadminTransactions2         from 'app/components/company-admin/transactions/page2';
import PageCadminPayroll               from 'app/components/company-admin/transactions/page-payroll';
import PageCadminPurchase              from 'app/components/company-admin/transactions/page-purchase';
import PageCadminVolAnalytics          from 'app/components/company-admin/volunteer/page-analytics';
import PageCadminVolDfd                from 'app/components/company-admin/volunteer/page-dfd';
import PageCadminVolTracking           from 'app/components/company-admin/volunteer/page-tracking';
import PageCadminVolEvents             from 'app/components/company-admin/volunteer/page-vol-events';
import PageCadminEditVolEvent          from 'app/components/company-admin/volunteer/page-vol-events-edit';
import PageCadminNewVolEvent           from 'app/components/company-admin/volunteer/page-vol-events-new';
import PageCadminWallet                from 'app/components/company-admin/wallet/page';
import PageCadminBudgets               from 'app/components/company-admin/wallet/page-budgets';
import PageCompanyDashboard            from 'app/components/company-dashboards/page-view';
import PageCreditCards                 from 'app/components/credit-cards/page-index';
import PageEmployeesInvite             from 'app/components/employees/page-invite';
import PageEveryCent                   from 'app/components/every-cent/index';
import PageError                       from 'app/components/errors/page';
import PageNoIe                        from 'app/components/errors/page-no-ie';
import PageGiftsSend                   from 'app/components/gifts/page-send';
import PageGiftsRedeem                 from 'app/components/gifts/page-redeem';
import PageGroup                       from 'app/components/groups/page-group';
import PageGroups                      from 'app/components/groups/page-index';
import PageHappenings                  from 'app/components/happenings/page-index';
import PageIntlOrgsSearch              from 'app/components/intl-orgs-search/page';
import PageBracket                     from 'app/components/madness/page-bracket';
import PageBracketBuilder              from 'app/components/madness/page-bracket-builder';
import PageBracketGame                 from 'app/components/madness/page-game';
import PageNonprofitDashboard          from 'app/components/nonprofit-dashboard/page-dashboard';
import PageNonprofitProfileEdit        from 'app/components/nonprofit-profile/page-edit';
import PageNonprofitProfilePreview     from 'app/components/nonprofit-profile/page-preview';
import PageNonprofitProfile            from 'app/components/nonprofit-profile/page-view';
import PageNonprofits                  from 'app/components/nonprofits/page';
import PageNteeCodes                   from 'app/components/nonprofits/page-ntee-codes';
// import PageStatic                      from 'app/components/pages/page-static';
import PageSlackConnectNonce           from 'app/components/slack/page-connect-nonce';
import PageStyleGuide                  from 'app/components/style-guide/page';
import PageUserProfile                 from 'app/components/user-profile/page';
import PageAccount                     from 'app/components/user-profile/page-account';
import PageNewPassword                 from 'app/components/user-profile/page-new-password';
import PageVolEvent                    from 'app/components/volunteer/page-event';
import PageVolunteer                   from 'app/components/volunteer/page-index';
import PageVolunteerMe                 from 'app/components/volunteer/page-me';
import PageWallet                      from 'app/components/wallet/page';
import PageNewOfflineDonation          from 'app/components/wallet/page-new-offline-donation';
import PageShowOfflineDonation         from 'app/components/wallet/page-show-offline-donation';
import { Pages }                       from 'app/constants';
import RoutingSlx                      from 'app/selectors/routing';

const pageComponentMap = {
  [Pages.ERROR]: PageError,
  [Pages.NOT_FOUND]: PageError[404],
  [Pages.FORBIDDEN]: PageError[403],
  [Pages.LOGIN]: PageLogin,
  [Pages.ONBOARDING]: PageOnboarding,
  [Pages.SSO_LOGIN]: PageSsoLogin,
  [Pages.SIGNUP]: PageSignup,
  [Pages.SIGNUP_EMP]: PageSignupEmp,
  [Pages.SIGNUP_IND]: PageSignupInd,
  [Pages.FORGOT_PASSWORD]: PageForgotPassword,
  [Pages.RESEND_EMP_INVITE]: PageResendEmpInvite,
  [Pages.STYLE_GUIDE]: PageStyleGuide,
  [Pages.SLACK_CONNECT_NONCE]: PageSlackConnectNonce,
  [Pages.VERIFY_EMAIL]: PageVerifyEmail,
  [Pages.EMAIL_VERIFICATION]: PageEmailVerification,
  [Pages['2FA']]: Page2fa,
  [Pages.APPROVAL_REQUIRED]: PageApprovalRequired,
  [Pages.EMPLOYEE_INVITE]: PageEmployeesInvite,
  [Pages.EVERY_CENT]: PageEveryCent,
  [Pages.COMPANY_DASHBOARD]: PageCompanyDashboard,
  [Pages.NTEE_CODES]: PageNteeCodes,
  [Pages.NONPROFITS]: PageNonprofits,
  [Pages.NONPROFIT_DASHBOARD]: PageNonprofitDashboard,
  [Pages.NONPROFIT_PROFILE]: PageNonprofitProfile,
  [Pages.NONPROFIT_PROFILE_EDIT]: PageNonprofitProfileEdit,
  [Pages.NONPROFIT_PROFILE_PREVIEW]: PageNonprofitProfilePreview,
  [Pages.CREDIT_CARDS]: PageCreditCards,
  [Pages.VOL_EVENT]: PageVolEvent,
  [Pages.VOLUNTEER]: PageVolunteer,
  [Pages.VOLUNTEER_ME]: PageVolunteerMe,
  [Pages.WALLET]: PageWallet,
  [Pages.OFFLINE_DONATIONS_NEW]: PageNewOfflineDonation,
  [Pages.OFFLINE_DONATIONS_SHOW]: PageShowOfflineDonation,
  [Pages.CLAIM]: PageClaim,
  [Pages.COLLECTION]: PageCollection,
  [Pages.GIFTS_SEND]: PageGiftsSend,
  [Pages.GIFTS_REDEEM]: PageGiftsRedeem,
  [Pages.BRACKET]: PageBracket,
  [Pages.BRACKET_BUILDER]: PageBracketBuilder,
  [Pages.BRACKET_GAME]: PageBracketGame,
  [Pages.GROUP]: PageGroup,
  [Pages.GROUPS]: PageGroups,
  [Pages.HAPPENINGS]: PageHappenings,
  [Pages.INTL_ORGS_SEARCH]: PageIntlOrgsSearch,
  [Pages.USER_PROFILE]: PageUserProfile,
  [Pages.ACCOUNT]: PageAccount,
  [Pages.NEW_PASSWORD]: PageNewPassword,
  [Pages.BROWSER_NOT_SUPPORTED]: PageNoIe,
  [Pages.CAMPAIGN]: PageCampaign,
  [Pages.CADMIN_DASHBOARD]: PageCadminDashboardView,
  [Pages.CADMIN_DRIVE_DONATIONS]: PageCadminDriveDonations,
  [Pages.CADMIN_DASHBOARD_EDIT]: PageCadminDashboardEdit,
  [Pages.CADMIN_EMPLOYEE]: PageCadminEmployee,
  [Pages.CADMIN_EMPLOYEES]: PageCadminEmployees,
  [Pages.CADMIN_EMPLOYEES_UPLOAD]: PageCadminEmployeesUpload,
  [Pages.CADMIN_CARDS]: PageCadminCards,
  [Pages.CADMIN_GIFTS]: PageCadminGifts,
  [Pages.CADMIN_GIFTS_NEW]: PageCadminGiftsNew,
  [Pages.CADMIN_GRANTS]: PageCadminGrants,
  [Pages.CADMIN_GRANTS_CREATE]: PageCadminGrantsCreate,
  [Pages.CADMIN_GROUP_EMPLOYEES]: PageCadminGroupEmployees,
  [Pages.CADMIN_GROUPS]: PageCadminGroups,
  [Pages.CADMIN_GROUPS_EDIT]: PageCadminGroupsEdit,
  [Pages.CADMIN_GROUPS_NEW]: PageCadminGroupsNew,
  [Pages.CADMIN_GROUPS_SETTINGS]: PageCadminGroupsSettings,
  [Pages.CADMIN_JUNK_DRAWER]: PageCadminJunkDrawer,
  [Pages.CADMIN_BRACKETS_DASHBOARD]: PageCadminBracketsDashboard,
  [Pages.CADMIN_BRACKETS_EDIT]: PageCadminBracketsEdit,
  [Pages.CADMIN_BRACKETS_NEW]: PageCadminBracketsNew,
  [Pages.CADMIN_BRACKETS]: PageCadminBrackets,
  [Pages.CADMIN_MATCH_INQUIRIES]: PageCadminMatchInquiries,
  [Pages.CADMIN_OFFLINE_DONATIONS]: PageCadminOfflineDonations,
  [Pages.CADMIN_MS_TEAMS_SETTINGS]: PageCadminMsTeamsSettings,
  [Pages.CADMIN_MATCH_SETTINGS]: PageCadminMatchSettings,
  [Pages.CADMIN_NONPROFITS]: PageCadminNonprofits,
  [Pages.CADMIN_NTEE_CODES]: PageCadminNteeCodes,
  [Pages.CADMIN_MATCHES]: PageCadminMatches,
  [Pages.CADMIN_SUBSCRIPTION]: PageCadminSubscription,
  [Pages.CADMIN_CATTRS]: PageCadminCattrs,
  [Pages.CADMIN_SSO]: PageCadminSso,
  [Pages.CADMIN_SLACK_SETTINGS]: PageCadminSlackSettings,
  [Pages.CADMIN_STATS]: PageCadminStats,
  [Pages.CADMIN_STATS2]: PageCadminStats2,
  [Pages.CADMIN_TRANSACTIONS2]: PageCadminTransactions2,
  [Pages.CADMIN_PAYROLL]: PageCadminPayroll,
  [Pages.CADMIN_PURCHASE]: PageCadminPurchase,
  [Pages.CADMIN_VOL_TRACKING]: PageCadminVolTracking,
  [Pages.CADMIN_VOL_ANALYTICS]: PageCadminVolAnalytics,
  [Pages.CADMIN_VOL_DFD]: PageCadminVolDfd,
  [Pages.CADMIN_VOL_EVENTS]: PageCadminVolEvents,
  [Pages.CADMIN_EDIT_VOL_EVENT]: PageCadminEditVolEvent,
  [Pages.CADMIN_NEW_VOL_EVENT]: PageCadminNewVolEvent,
  [Pages.CADMIN_CAMPAIGNS]: PageCadminCampaigns,
  [Pages.CADMIN_NEW_CAMPAIGN]: PageCadminNewCampaign,
  [Pages.CADMIN_CAMPAIGNS_TEMPLATES]: PageCadminCampaignsTemplates,
  [Pages.CADMIN_EDIT_CAMPAIGN]: PageCadminEditCampaign,
  [Pages.CADMIN_WALLET]: PageCadminWallet,
  [Pages.CADMIN_BUDGETS]: PageCadminBudgets,
  [Pages.BS_BALANCE]: PageBackstageBalance,
  [Pages.BS_BALANCES]: PageBackstageBalances,
  [Pages.BS_BALANCES_TRANSFER]: PageBackstageBalancesTransfer,
  [Pages.BS_CARDS]: PageBackstageCards,
  [Pages.BS_COLLECTIONS]: PageBackstageCollections,
  [Pages.BS_COMPANIES]: PageBackstageCompanies,
  [Pages.BS_COMPANY]: PageBackstageCompany,
  [Pages.BS_CONFIG]: PageBackstageConfig,
  [Pages.BS_DASHBOARD_OVERVIEW]: BackstageDashboardOverviewPage,
  [Pages.BS_INDEX]: PageBackstageIndex,
  [Pages.BS_NONPROFITS]: PageBackstageNonprofits,
  [Pages.BS_NONPROFIT]: PageBackstageNonprofit,
  [Pages.BS_NONPROFIT_REQUESTS]: PageBackstageNonprofitRequests,
  [Pages.BS_NTEE_CODES]: PageBackstageNteeCodes,
  [Pages.BS_PENDING_CLAIMS]: PageBackstagePendingClaims,
  [Pages.BS_PRICING]: PageBackstagePricing,
  [Pages.BS_USER]: PageBackstageUser,
  [Pages.BS_USERS]: PageBackstageUsers,
  [Pages.BS_FAILED_CHARGES]: PageBackstageFailedCharges,
  [Pages.BS_FAILED_DISBURSEMENTS]: PageBackstageFailedDisb,
  [Pages.BS_FFS]: PageBackstageFeatureFlags,
};

// these pages will use key={path}
// handy for forcing paths like /users/:id to remount the page component when the id changes
const keyOnPath = [
  Pages.BS_BALANCE,
];

class CurrentPage extends React.PureComponent {

  render() {
    const { page, path } = this.props;
    const shouldKeyOnPath = keyOnPath.includes(page);
    const props = shouldKeyOnPath ? {key: path} : {};
    const Component = pageComponentMap[page] || PageError[404];
    return <Component {...props} />;
  }

}

const stateToProps = (state) => ({
  page: RoutingSlx.page(state),
  path: RoutingSlx.path(state),
});

export default connect(stateToProps)(CurrentPage);
